.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 50px;
    left: 0;
    transform: translateX(-120%);
    transition: all 0.2s;
}

.menu.active {
    transform: translateX(0);
}

.blur {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    position: absolute;
}

@media (min-width: 768px) {
    .blur {
      left: 20%;
    }
}
  
@media (max-width: 767px) {
    .blur {
      left: 35%;
    }
}

.menu__content {
    width: 20%;
    height: 100%;
    background-color: rgb(70 72 102 / 93%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s;
}

.menu__header {
    padding: 20px;
    font-size: 2rem;
    color: white;
    border-bottom: 1px solid rgb(209 232 226 / 60%);;
    width: 100%;
    text-align: center;
}

ul li a {
    color: white;
    text-decoration: none;
}

ul {
    text-align: center;
    font-size: 1.5rem;
    padding-top: 20px;
    width: 80%;
}

.material-symbols-outlined {
    color: white;
    margin-left: 40px;
    font-size: 35px;
}

.li-item {
    height: 60px;
}

li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 0.5px rgb(209 232 226 / 35%);
}

@media screen and (max-width: 768px) {
    .menu__content {
        width: 60%;
    }
    .blur {
        backdrop-filter: none;
    }
}

a:hover {
    color: rgb(255, 255, 255);
    font-size: 105%;
    font-weight: 600;
    text-shadow: 
       5px  5px 10px #D1D7E0,
       5px -5px 10px #D1D7E0,
      -5px  5px 10px #D1D7E0,
      -5px -5px 10px #D1D7E0;
}
