.contactsPage {
    background-image: url(./../../assets/img/bi009.jpg);
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    .info {
        width: 50%;
        min-width: 375px;
        .phone {
            a {
                text-decoration: none;
                font-size: 40px;
                color: #29648A;
                margin-top: 200px;
            }
            margin-top: 100px;
            text-align: center;
        }
        .vwt {
            text-align: center;
            margin-bottom: 50px;
        }
        .name {
            border-left: 1px solid #2E9CCA;
            font-size: 30px;
            margin-bottom: 25px;
            margin-left: 15%;
            text-align: left;
            padding-left: 23px;
            color: #25274D;
            font-weight: 600;
            li {
                font-size: 20px;
                margin-top: 20px;
            }
        }
        .mail {
            border-left: 1px solid #2E9CCA;
            text-decoration: none;
            font-size: 20px;
            color: #25274D;
            margin-bottom: 30px; 
            margin-top: 60px;
            margin-left: 15%;
            text-align: left;
            padding-left: 23px;
        }
        .position {
            color: #25274D;
            font-size: 20px;
            margin-bottom: 25px;
            margin-left: 15%;
            text-align: left;
            padding-left: 23px;
            border-left: 1px solid #2E9CCA;
            li {
                height: auto;
                a {
                    color: #039be5;
                }
            }
        }
        .links-to-socialnetwork {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 60px;
            margin-bottom: 50px;
            max-height: 60px;
            .social-network:hover {
                -webkit-transform: scale(1.3);
                -ms-transform: scale(1.3);
                transform: scale(1.3);
                transition-duration: 0.5s;
            }
        }
    }   
    .its-me:hover {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        transition-duration: 0.5s;
    }
}
