.about-me {
    height: 90vh;
    .main-wrapper {
        background-color: #464866;
        color: #D1D7E0; 
        margin-top: 100px;
        margin-bottom: 50px;
        min-height: 750px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .img {
            min-height: 650px;
            display: inline-block;
            width: 50%;
            background: url(./../../assets/img/bi003.jpg) center center no-repeat;
            background-size: cover;
            min-width: 375px;
        }
        .info-boxx {
            color: #d2d3dd;
            background-color: #29648A;
            min-height: 650px;
            max-width: 900px;
            text-align: center;
            padding-top: 40px;
            font-family: 'Comfortaa', cursive;
            div {
                margin-left: 5%;
                margin-right: 5%;
                margin-bottom: 10px;
                font-size: 40px;
            }
            .other-info {
                font-size: 22px;
            }
        }
    }
}

.my-cv {

    background-color: #ffe3c9;
    height: auto;
    text-align: center;
    font-family: 'Comfortaa', cursive;
    h5 {
        color: #464866;
        letter-spacing: 5.0px;
        margin-bottom: 70px;
    }
}

.my-expirience {
    font-size: 50px;
    color:  #464866;
    font-weight: 500;
    font-family: 'Ubuntu', sans-serif;
    padding-top: 50px;
}
.expirience {
        margin-left: 3%;
        margin-right: 3%;
        color:  #464866;
    }

.heading {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
    color:  #464866;
    padding-top: 20px;
    padding-bottom: 20px;
}
.string-info {
    font-size: 20px;
    text-align: left;
    padding-left: 10%;
    font-family: 'Ubuntu', sans-serif;
    color:  #464866;
    margin-bottom: 10px;
    a:hover {
        color: rgb(64, 64, 195);
        transition: all 0.5s;
    }
} 

.pic-box {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 50px;
    .about-img1 {
        height: 360px;
        width: 360px;
        border-radius: 50%;
        background-image: url(./../../assets/img/93.jpg);    
    }
}