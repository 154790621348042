.wrap {
    background: linear-gradient(90deg, #AAABB8 50%, #ffe3c9 50%);
    position: relative;
    box-sizing: border-box;
    margin-top: 50px;
    min-height: 96vh;
    padding-top: 55px;
    padding-bottom: 55px;
    .box-title-post-page {
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: auto;
        .title-data {
            margin: 0;
            margin-right: 2%;
            min-height: 160px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 60%;
            min-width: 345px;
            .date-post {
                font-size: 16px;
                text-align: right;
                color: #29648A;
                margin-bottom: 25px;
            }
            .title-post-page {
                text-align: right;
                font-size: 20px;
            }
        }
        .btn-component {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
    .string {
        padding-top: 45px;
        font-size: 18px;
        font-weight: normal;
        text-indent: 50px;
    }
    .post-author {
        font-size: 18px;
        padding-top: 30px;
        padding-bottom: 20px;
        text-align: right;
        padding-right: 7%;
        font-style: italic;
        margin-bottom: 25px;
    }
}
.wrap:before, 
.wrap:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 10px;
    background: linear-gradient(90deg, #ffe3c9 50%, #AAABB8 50%);
    left: 25%;
}
.wrap:before {
    top: 25px;
}
.wrap:after {
    bottom: 25px;
}
.wrap div {
    background: url(./../../assets/img/bi009.jpg);
    margin: 0;
    text-align: justify;
    color: #464866; 
    margin-left: 4%;
    margin-right: 4%;
}

