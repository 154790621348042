.box-title-post-page {
    color: #25274D;
   font-size: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 50px;
   margin-bottom: 15px;
   background-color: #AAABB8;
   padding-top: 30px;
   padding-bottom: 50px;
   font-weight: 600;
} 

.post-page-img {
   margin-top: 30px;
   height: 500px;
   width: 888px;
   background-image: url(./../../assets/img/bi0021.jpg);
   object-fit: cover;
}

.post-page-section {
   background-color: #AAABB8;
   min-height: 100vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: flex-start;
   font-family: 'Comfortaa', cursive;
   .title-postpage {
      margin-top: 70px;
      font-size: 35px;
      font-weight: 700;
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #464866;
      background-image: url(./../../assets/img/bi00011.jpg);
   }
   .post-page-box {
      height: auto;
      margin-top: 50px;
      max-width: 100%;
      .item-post-page-small {
         display: flex;
         justify-content: space-evenly;
         flex-wrap: wrap;
         .item-public {
            width: 335px;
            min-height: 400px;
            padding: 20px;
            background-color: #ffe3c9;
            border-radius: 20px;
            color: #464866;
            height: auto;
            margin: 20px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .item-box {
               .date-post {
                  font-weight: 600;
                  display: flex;
                  justify-content: space-between;
               }
               .item-post-page {
                  margin-top: 10px;
                  .post-title {
                     font-size: 22px;
                     font-weight: 600;
                     margin-bottom: 10px;
                  }
                  .post-content {
                     text-align: right;
                     font-size: 18px;
                  }
               }
            }
            .read-post {
               text-align: right;
               font-size: 16px;
               letter-spacing: 2px;
               font-style: italic;
               background: radial-gradient(#ffd2a9, #ffe3c9);
               font-weight: 600;
            }
         }
         .item-public:hover {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.1);  
            transition-duration: 0.5s; 
            text-shadow: none;
            font-weight: normal;
            color: #25274D;
            background-color: #fed3aa;
            box-shadow: 3px 3px 10px 5px rgb(76, 79, 118), 0 0 4px rgba(61, 71, 144, 0.5) inset;
         } 
      }
   }
}


