.plus {
    content: url(plus.svg);
    width: 20px;
    height: 20px;
}
  
.minus {
   content: url(minus.svg);
   width: 20px;
   height: 20px;
}

.question-title {
   font-size: 25px;
   width: auto;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-left: 70px;
   padding-right: 40px;
   background-color: #464866;
   color: #D1D7E0;
   min-height: 40px;
   margin-left: 3%;
   margin-bottom: 12px;
   margin-right: 3%;
   border-radius: 10px;
   padding-bottom: 10px;
   padding-top: 7px;
   div {
      width: 96%;
   }
} 

.answer {
   background-color: #ffe3c9;
   width: auto;
   display: flex;
   margin-left: 4%;
   margin-right: 4%;
   border-radius: 3px;
   margin-top: 5px;
   padding-left: 20px;
   padding-right: 22px;
   border: 1.5mm ridge;
   font-weight: 600;
   letter-spacing: 0.5px;
   margin-bottom: 10px;
}

.answers {
   color: #25274D;
   font-family: 'Comfortaa', cursive;
   font-size: 18px;
   text-indent: 60px;
   margin-top: 10px;
   margin-bottom: 10px;
   text-align: justify;
}

.h2act {
    font-weight: 600;
    color: #f5f9ff;
    letter-spacing: 0.5px;
}

.box-faq {
   color: #25274D;
   font-size: 32px;
   font-family: 'Comfortaa', cursive;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-top: 40px;
   margin-bottom: 15px;
   background-color: #AAABB8;
   padding-top: 30px;
   padding-bottom: 40px;
   font-weight: 600;
} .faq-img {
   margin-top: 30px;
   height: 520px;
   background: url(./../../assets/img/faq002.jpg) center center no-repeat;
   width: -webkit-fill-available;
}

.main-title-faq {
   text-align: center;
}

.wrapper:last-child {
   margin-bottom: 30px;
}