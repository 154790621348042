.services-page{
    font-family: 'Comfortaa', cursive;
}
.services-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 30px;
    background-color: #29648A;
    padding-top: 30px;
    padding-bottom: 50px;
    
} .serv-img {
    height: 500px;
    width: -webkit-fill-available;
    background: url(./../../assets/img/bi004.jpg) center center no-repeat;
}

.focus-in-contract-bck {
    margin-bottom: 15px;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 35px;
    font-weight: 700;
    color: #FFCB9A;
    text-align: center;
}

.services-box2 {
    background-color: #AAABB8;
    height: auto;
    color: #25274D;
        .who-help-me {
        font-size: 28px;
        text-align: center;
        letter-spacing: 2px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 900;
    }
}

.who-li {
    margin-left: 10%;
    font-size: 18px;
    font-weight: 800;
}

.main-wrapper2 {
    background-color: #AAABB8;
    color: #D1D7E0; 
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    align-items: stretch;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 50px;
    padding-top: 20px;
    .info-box-second {
        border: 1.5mm ridge;
        min-height: 520px;
        min-width: 375px;
        max-width: 48%;
        text-align:justify;
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 25px;
        padding-bottom: 25px;
        font-family: 'Comfortaa', cursive;
        background-color:#ffe3c9;
        color: #25274D; 
        margin-bottom: 10px;
        li {
            font-size: 16px;
            font-weight: 600;
            text-indent: 20px;
            height: auto;
            margin-bottom: 15px;
            text-align: left;
            letter-spacing: 0.8px;
        }
        .li-total {
            font-weight: 900;
            font-size: 18px;
            margin-top: 40px;
        }
    }
}

.box-second-img1 {
    height: 420px;
    display: inline-block;
    width: 100%;
    background: url(./../../assets/img/bi005.jpg) center center no-repeat;
    min-width: 312px;
}

.box-second-img2 {
    background: url(./../../assets/img/bi096.jpg) center center no-repeat;
    height: 420px;
    display: inline-block;
    width: 100%;
    min-width: 312px;
}

.box-second-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.services-box-bottom {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    background-color: #29648A;
    padding-top: 30px;
    padding-bottom: 50px;
}

.box-bootom-dark {
    padding: 50px 3% 50px 3%;
    height: auto;
    width: auto;
    background-color: #25274D;
}

.box-bootom-light {
    padding: 30px 8% 30px 8%;
    height: auto;
    background-color:#AAABB8;
    ul {
        width: auto;
    }
    li {
        border-bottom: none;
        text-align: left;
        color: #25274D;
        font-weight: 600;
        margin-bottom: 10px;
    }
}