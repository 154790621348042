.main-page {
    background-image: url(./../../assets/img/png001111.jpg);
    height: 100vh;
    padding-top: 55px;
    background-size: 100%;
    background-repeat: no-repeat;
} 

.main-message {
    font-family: 'Nunito Sans', sans-serif;
} 

.main-sentense {
    text-align: center;
    margin-top: 200px;
    color: #464866;
    font-weight: 600;
    text-shadow: 
    5px  5px 20px #D1D7E0, 
    5px -5px 20px #D1D7E0, 
   -5px  5px 20px #D1D7E0, 
   -5px -5px 20px #D1D7E0; 
    h2 {
        font-size: 34px;
    }
    h4 {
        letter-spacing: 5.5px;
        margin-top: 50px;
    } h5 {
        margin-top: 120px;
        letter-spacing: 8px;
        font-weight: 700;
        margin-bottom: 88px;
    }
} 

.main-info {
    height: auto;
    width: auto;
    background-image: url(./../../assets/img/bi009.jpg);
    h3 {
        height: 150px;
        color: #2E9CCA;
    }
    .main-citation {
        font-family: 'Comfortaa', cursive;
        height: auto;
        background-color: #ffe3c9;
        color: #25274D; 
        padding: 20px 5% 20px 5%;
        text-align: justify;
        h4 {
            line-height: inherit;
            text-indent: 70px;
            font-size: 20px;
            font-weight: 600;
        }
        .tom-hanks {
            text-align: right;
            margin-top: 20px;
            font-weight: 600;
        }
    }
}
.main-wrapper {
    background-color: #25274D;
    color: #D1D7E0; 
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    .img {
        height: 600px;
        display: inline-block;
        background: url(./../../assets/img/png00113.jpg) center center no-repeat;
        min-width: 375px;
        width: 100vw;
        max-width: 980px;
    }
    .info-box {
        max-width: 800px;
        min-height: 600px;
        text-align: center;
        font-family: 'Comfortaa', cursive;
        h3 {
            margin-left: 10%;
            margin-right: 10%;
        }
        h5 {
            line-height: inherit;
            margin-bottom: 20px;
            letter-spacing: 1px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.h3-wrapper1 {
    font-family: 'Comfortaa', cursive;
    color: #25274D;
    height: auto;
    padding-bottom: 20px;
    margin-top: 0;
    background-color: #AAABB8;
    text-align: center;
    font-size: 30px;
    padding-top: 30px;
    font-weight: 600;
}
.main-wrapper1 {
    background-color: #AAABB8;
    color: #D1D7E0; 
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 1%;
    justify-content: space-around;
    .info-box-third {
        border: 1.5mm ridge;
        min-height: 520px;
        max-width: 31%;
        min-width: 368px;
        text-align:justify;
        padding: 15px;
        font-family: 'Comfortaa', cursive;
        background-color:#ffe3c9;
        color: #25274D;
        padding-top: 50px;
        margin-bottom: 10px;
        li {
            font-size: 20px;
            font-weight: 600;
            text-indent: 20px;
            height: auto;
            margin-bottom: 15px;
            text-align: left;
        }
    }
}