nav {
    height: 50px;
    width: 100%;
    background-color: #25274D;
    position: fixed;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.layout-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

p {
  text-align: justify;
  text-indent: 60px;
  font-size: 16px;
  margin: 20px;
}

.burger-btn {
  width: 30px;
  height: 20px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}

.burger-btn:before {
  content: '';
  position: absolute;
  top: 0;
  width: 30px;
  background-color: #D1D7E0;
  height: 2px;
}

.burger-btn span {
  position: absolute;
  top: 9px;
  width: 30px;
  background-color: #D1D7E0;
  height: 2px;
}

.burger-btn:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 30px;
  background-color: #D1D7E0;
  height: 2px;
}

.footer {
    height: 60px;
    width: 100%;
    background-color: #25274D;
    color: white;
    font-size: 35px;
    text-align: center;
    display: none;
}

.btn-info-detail {
  width: 150px;
  margin: 0 auto;
  display: block;
  color: #D1D7E0;
  padding: 15px 25px;
  background-color: #25274D;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
}
.btn-info-detail:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.hi-today-is {
  height: 62px;
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 14px;
  justify-content: flex-end;
}

.menu-top {
  width: 80%;
}